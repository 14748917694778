import React from 'react'

import { LayoutLoggedIn } from '../../components/views/layout'
import MyDetails from '../../components/views/profile/mydetails'

export default function DetailsPage() {
   return (
      <LayoutLoggedIn>
         <MyDetails />
      </LayoutLoggedIn>
   )
}
